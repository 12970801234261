<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          admin
        </p>
        <!-- <span class="user-status">{{ profile.role }}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('logout') }}</span>
    </b-dropdown-item>
      <b-dropdown-item
      v-if="!userData.isAdmin"
      link-class="d-flex align-items-center"
      @click="changePassword"
    >
      <feather-icon
        size="16"
        icon="KeyIcon"
        class="mr-50"
      />
      <span>{{ $t('changePassword') }}</span>
    </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapActions } from 'vuex';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('@USER_PROFILE')) || {},
      avatarText,
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      changePassword: 'auth/changePassword'
    }),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('@ACCESS_TOKEN');
      localStorage.removeItem('@USER_PROFILE');
      sessionStorage.removeItem('INIT_OBJ');
      sessionStorage.removeItem('TOTAL');

      this.$router.push({ name: 'login' });
      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
