<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    ref="virticalmenu"
    id="virticalmenu"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span>
                <b-img
                  :src="imgUrl"
                  alt="logo"
                  style="width: 95%"
                />
              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>
          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="modifiedNavMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
// import { PROFILE_KEY } from '@/libs/acl/config';
// import { reportsRoutes, reportsPermissions } from '@/router/routes/reportsRoutes'
import navMenuItems from '@/navigation/vertical/index'
import adminMenu from '@/navigation/vertical/adminMenu';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import store from '@/store/index';
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark' || store.state.appConfig.layout.skin === 'semi-dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoTwo = require('@/assets/images/menuLogoDark.png');
        return this.logoTwo;
      }
      if (store.state.appConfig.layout.skin === 'light' || store.state.appConfig.layout.skin === 'bordered') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoTwo = require('@/assets/images/LOGO FILES/login.png');
        return this.logoTwo;
      }
      return this.logoTwo;
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)
    const profile = JSON.parse(window.localStorage.getItem('@USER_PROFILE') || '{}');
    const { skin } = useAppConfig()
    const attachedchildren = [];
    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage, logoTwo } = $themeConfig.app
    // const profile = JSON.parse(window.localStorage.getItem(PROFILE_KEY) || '{}');
    // reportsPermissions.forEach((r) => {
    //   if (profile.permissions.some(item => r.permissions.includes(item))) {
    //      attachedchildren.push(r.route);
    //   }
    // })
    let menu = [];
    if (profile.isAdmin) {
      menu = adminMenu
    } else {
      menu = navMenuItems
    }

    const modifiedNavMenuItems = menu.filter(obj => {
    return obj.title !== 'reports';
});
    // if (profile.isAdmin) {
    //   modifiedNavMenuItems.push({
    // title: 'reports',
    // icon: 'FileTextIcon',
    // children: reportsRoutes
    // })
    // }
    if (attachedchildren.length > 0) {
      modifiedNavMenuItems.push({
      title: 'reports',
      icon: 'FileTextIcon',
      children: attachedchildren
    });
    }
    return {
      modifiedNavMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      logoTwo
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
