//  viewAssets, addAssets, editAssets, deleteAssets,
// viewQuotations, addQuotations, editQuotations, deleteQuotations,
// viewSalesOrders, addSalesOrders, editSalesOrders, deleteSalesOrders,
// addSalesWithRemainder, modifySalesPrice, viewReturnSalesTransactions, addReturnSalesTransactions, editReturnSalesTransactions, deleteReturnSalesTransactions, viewPurchOrders, addPurchOrders, editPurchOrders, deletePurchOrders, viewPurchTransactions, addPurchTransactions, editPurchTransactions, deletePurchTransactions, viewReturnPurchTransactions, addReturnPurchTransactions, editReturnPurchTransactions, deleteReturnPurchTransactions, viewAdditionOrders, addAdditionOrders, editAdditionOrders, deleteAdditionOrders ]
export default [
    {
      title: 'admin-home',
      route: 'admin-home',
      icon: 'HomeIcon',
    },
    {
      title: 'current-users',
      route: 'current-users',
      icon: 'UsersIcon',
    },
    {
      title: 'notActive-users',
      route: 'notActive-users',
      icon: 'UserXIcon',
    },
    {
      title: 'pricing-policy',
      route: 'pricing-policy',
      icon: 'DollarSignIcon',
    },
    {
      title: 'plans',
      route: 'plans',
      icon: 'UploadCloudIcon',
    },
    {
      title: 'upgradePlan',
      route: 'upgradePlan',
      icon: 'GridIcon',
    },
    {
      title: 'discount-code',
      route: 'discount-code',
      icon: 'AwardIcon',
    },
    {
      title: 'fiscalYear',
      route: 'fiscalYear',
      icon: 'CalendarIcon',
    },
    {
      title: 'adminRegester',
      route: 'adminRegister',
      icon: 'DatabaseIcon',
    },
    // {
    //   title: 'history',
    //   route: 'history',
    //   icon: 'ListIcon',
    // },
  ];
