<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
      row: !item.icon,
    }"
  >
    <feather-icon
      v-bind:class="item.icon ? 'd-none' : 'd-block mt-1 ml-1 text-warning'"
      v-if="item.isFav === true"
      icon="StarIcon"
      class="cursor-pointer fill-current"
      size="16"
      @click="
        () => {
          makeFav(item);
        }
      "
    />
    <feather-icon
      v-bind:class="item.icon ? 'd-none' : 'd-block mt-1 ml-1 text-warning'"
      v-if="item.isFav === false"
      icon="StarIcon"
      class="cursor-pointer"
      size="16"
      @click="
        () => {
          makeFav(item);
        }
      "
    />
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon
        :class="item.icon ? 'd-block' : 'd-none'"
        :icon="item.icon || ''"
      />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import navMenuItems from '@/navigation/vertical/index';
import adminMenu from '@/navigation/vertical/adminMenu';
import { useUtils as useAclUtils } from '@core/libs/acl';
import { BLink, BBadge } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';

export default {
  name: 'VerticalNavMenuLink',
  components: {
    BLink,
    BBadge,
  },
  data() {
    return {
      navMenuItems,
      adminMenu,
      favMenu: [],
      UpdatedMenu: [],
    };
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();
    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
